import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./success.css";

import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./Payment.css";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { green } from "@mui/material/colors";

import * as api from "../api/vote";

function PaystackCallback() {
  let history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const trxref = queryParams.get("trxref");
  const reference = queryParams.get("reference");

  console.log(trxref, reference);
  const [isloading, setLoading] = useState(true);
  const [resStatus, setResStatus] = useState("");
  useEffect(() => {
    api
      .paystackCallback(trxref)
      .then((res) => {
        if (res?.status === true) {
          setResStatus("success");
        } else {
          setResStatus("exists");
        }
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
        setResStatus("failed");
        history.push("/page/404");
      })
      .finally(() => setLoading(false));
  }, [history, trxref]);

  const PageLoading = () => {
    return (
      <>
        <div style={{ marginTop: "1%" }}>
          <div class="card">
            <div
              className="illustration"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span
                className="time"
                style={{
                  color: "#fefefe",
                  fontSize: "100px",
                  lineHeight: "200px",
                }}
              >
                <img
                  src="/processing.jpeg"
                  alt="Failed"
                  width={"100%"}
                  style={{ marginTop: "30%" }}
                />
              </span>
            </div>
            <h1>Processing...</h1>
            <p>
              Validating Transaction
              <br /> Please Wait!
            </p>
          </div>
        </div>
      </>
    );
  };

  const SuccessPage = () => {
    return (
      <div style={{ marginTop: "1%" }}>
        <div class="card">
          <div className="illustration">
            <i class="checkmark">✓</i>
          </div>
          <h1>Success</h1>
          {resStatus === "success" && (
            <p>
              Payment has been completed
              <br /> Successfully!
            </p>
          )}
          {resStatus === "exists" && <p>Payment has already been processed!</p>}

          <p>
            <Button
              component={RouterLink}
              to="/"
              variant="contained"
              style={{
                backgroundColor: green[500],
                color: "white",
                textDecoration: "none",
                marginTop: "5%",
              }}
            >
              Go Home
            </Button>
          </p>
        </div>
      </div>
    );
  };

  const FailedPage = () => {
    return (
      <div style={{ marginTop: "1%" }}>
        <div class="card">
          <div
            className="illustration"
            style={{
              background: "#b04b4b",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              className="time"
              style={{
                color: "#fefefe",
                fontSize: "100px",
                lineHeight: "200px",
              }}
            >
              <img
                src="/failed.webp"
                alt="Failed"
                width={"100%"}
                style={{ marginTop: "30%" }}
              />
            </span>
          </div>
          <h1 style={{ color: "#b04b4b" }}>Transaction Failed</h1>
          <p>
            An Error occured while processing payment
            <br /> Please Try Again!
          </p>
          <p>
            <Button
              component={RouterLink}
              to="/"
              variant="contained"
              style={{
                backgroundColor: "#b04b4b",
                color: "white",
                textDecoration: "none",
                marginTop: "5%",
              }}
            >
              Go Home
            </Button>
          </p>
        </div>
      </div>
    );
  };

  var viewRenderer = <></>;

  if (isloading) {
    viewRenderer = PageLoading();
  } else if (resStatus === "success" || resStatus === "exists") {
    viewRenderer = SuccessPage();
  } else {
    viewRenderer = FailedPage();
  }

  return (
    <React.Fragment>
      <Container
        container='true'
        id='header'
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)',
        }}>
        <div
          className='contain'
          style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div
            className='item'
            style={{ marginTop: '29px', marginBottom: '29px' }}>
            <div className='logo'>
              <a href='https://realityshows.3news.com' title='Reality shows'>
                <img
                  src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
                  alt='Reality Shows Portal'
                  style={{ width: '140px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>

      {viewRenderer}

      <Container container='true'>
        <div className='copyright__logo'>
          <a href='https://realityshows.3news.com' title='Reality shows'>
            <img
              src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
              alt='Reality Shows Portal'
              style={{
                width: '100px',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            />
          </a>
        </div>
        <div className='copyright'>
          <div className='copyright__item1'>
            <p className='copyright__text'>
              Copyright © 2022 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className='copyright__item2'>
            <p className='copyright__text'>Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
      <Backdrop
        style={{
          color: '#fff',
        }}
        open={false}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}

export default PaystackCallback;
