import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import LockResetIcon from "@mui/icons-material/LockReset";
import PendingIcon from "@mui/icons-material/Pending";
import PeopleOutlineRoundedIcon from "@mui/icons-material/PeopleOutlineRounded";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { regions } from "../utils/regions.js";
import { statuses } from "../utils/status.js";

import * as api from "../api/vote";

import "./Payment.css";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  media: {
    height: 0,
    paddingTop: "96.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  section1: {
    margin: theme.spacing(1, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  voteNumber: {
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
    cursor: "pointer",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  numberInput: {
    textAlign: "center !important",
  },
  cardProfile: {
    backgroundColor: "#ffffff",
    color: "#1d2438",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    border: "1px solid rgb(229, 234, 242)",
    borderRadius: "8px",
    padding: "24px",
    boxShadow: "rgb(0 0 0 / 7%) 0px 0px 21px 1px",
    minHeight: "100%",
    display: "flex",
    flexDirection: "row",
    "-webkit-box-align": "center",
    alignItems: "center",
  },
}));

const GmbAuditions = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [formDetails, setFormDetails] = useState([]);
  const [stats, setStats] = useState({
    show_all: 0,
    completed: 0,
    progress: 0,
    pending: 0,
  });
  const [formatData, setFormatData] = useState([]);

  const [selectionModel, setSelectionModel] = React.useState([]);
  const rows: GridRowsProp = formDetails == null ? [] : formatData;

  const format_year = (year) => {
    let new_year = year.split("/");
    let new_date = new_year[0] + "-" + new_year[2] + "-" + new_year[1];
    return new_date;
  };

  const columns: GridColDef[] = [
    {
      field: "avatar",
      headerName: "Avatar",
      renderCell: (params: GridRenderCellParams<Date>) => {
        return (
          <Avatar
            alt="Remy Sharp"
            sx={{ width: 56, height: 56 }}
            src={params.value?.img_url}
          />
        );
      },
      filterable: false,
    },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "dob",
      width: 150,
      type: "date",
      renderHeader: (params: GridColumnHeaderParams) => (
        <strong>
          {"Date of Birth "}
          <span role="img" aria-label="enjoy">
            🎂
          </span>
        </strong>
      ),
    },
    {
      field: "age",
      headerName: "Age",
      valueGetter: (params) => {
        let dob = new Date(format_year(params.row.dob));
        let month_diff = Date.now() - dob.getTime();
        let age_dt = new Date(month_diff);
        let year = age_dt.getUTCFullYear();
        let age = Math.abs(year - 1970);
        return age;
      },
    },
    { field: "place_of_birth", headerName: "Place of Birth", width: 150 },
    {
      field: "height",
      headerName: "Height",
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value} m`;
      },
    },
    {
      field: "weight",
      headerName: "Weight",
      width: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value} kg`;
      },
    },
    {
      field: "region",
      headerName: "Region",
      width: 150,
      type: "singleSelect",
      valueOptions: regions,
    },
    { field: "location", headerName: "Location", width: 150 },
    { field: "occupation", headerName: "Occupation", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      type: "singleSelect",
      valueOptions: statuses,
    },
    { field: "mentor", headerName: "Mentor", width: 150 },
    { field: "name_of_chief", headerName: "Name of Chief", width: 150 },
    { field: "languages_spoken", headerName: "Languages Spoken", width: 150 },
    { field: "biography", headerName: "Biography", width: 150 },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 150,
    },
    { field: "father_name", headerName: "Father's Name", width: 150 },
    { field: "father_region", headerName: "Father's Region", width: 150 },
    { field: "father_hometown", headerName: "Father's Hometown", width: 150 },
    { field: "mother_name", headerName: "Mother's Name", width: 150 },
    { field: "mother_region", headerName: "Mother's Region", width: 150 },
    { field: "mother_hometown", headerName: "Mother's Hometown", width: 150 },
    { field: "created_at", headerName: "Created At", width: 150, type: "date" },
    { field: "updated_at", headerName: "Updated At", width: 150, type: "date" },
  ];

  React.useEffect(() => {
    // When the user scrolls the page, execute myFunction
    window.onscroll = () => {
      var header = document.getElementById("header");

      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  }, []);

  React.useEffect(() => {
    api
      .getFormDetails()
      .then((response) => {
        setLoading(false);
        setFormDetails(response.data.gmb_form_details);
        // setUser(response.user);
      })
      .catch((err) => {
        const error = err;
        setLoading(false);
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    api
      .getStats()
      .then((response) => {
        setStatsLoading(false);
        setStats({
          show_all: response.show_all,
          completed: response.completed_forms,
          progress: response.in_progress,
          pending: response.pending,
        });
        //  setFormDetails(response.data.gmb_form_details);
        // setUser(response.user);
      })
      .catch((err) => {
        const error = err;
        setStatsLoading(false);
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const formData = formDetails?.map((details) => ({
      id: details?.id,
      avatar: details?.profile_picture,
      name: details?.personal_info?.full_name,
      dob: details?.personal_info?.dob,
      place_of_birth: details?.personal_info?.place_of_birth,
      height: details?.personal_info?.height,
      weight: details?.personal_info?.weight,
      region: details?.personal_info?.region,
      location: details?.location,
      occupation: details?.occupation,
      status: details?.status,
      mentor: details?.mentor,
      name_of_chief: details?.name_of_chief,
      languages_spoken: details?.languages_spoken,
      biography: details?.biography,
      phone_number: details?.phone_number_info?.number?.number,
      father_name: details?.family_info?.father_info?.name,
      father_region: details?.family_info?.father_info?.region,
      father_hometown: details?.family_info?.father_info?.hometown,
      mother_name: details?.family_info?.mother_info?.name,
      mother_region: details?.family_info?.mother_info?.region,
      mother_hometown: details?.family_info?.mother_info?.hometown,
      created_at: details?.created_at,
      updated_at: details?.updated_at,
    }));
    setFormatData(formData);
  }, [formDetails]);

  return (
    <>
      <Container
        container='true'
        id='header'
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)',
        }}>
        <div
          className='contain'
          style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div
            className='item'
            style={{ marginTop: '29px', marginBottom: '29px' }}>
            <div className='logo'>
              <a href='https://realityshows.3news.com' title='Reality shows'>
                <img
                  src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
                  alt='Reality Shows Portal'
                  style={{ width: '140px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container
        container='true'
        spacing={3}
        maxWidth='lg'
        style={{ marginBottom: '100px', marginTop: '100px' }}>
        <Grid container spacing={2} style={{ marginBottom: '100px' }}>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={classes.cardProfile}>
              <Box
                sx={{
                  width: '60px',
                  height: '60px',
                  marginRight: '16px',
                  display: 'flex',
                  borderRadius: '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 151, 119, 0.2)',
                  WebkitBoxAlign: 'center',
                  WebkitBoxPack: 'center',
                }}>
                <PeopleOutlineRoundedIcon
                  style={{ color: 'rgb(255, 151, 119)', fontSize: '2rem' }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    color: 'rgb(148, 164, 196)',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  Total Applications
                </Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {statsLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    stats.show_all
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={classes.cardProfile}>
              <Box
                sx={{
                  width: '60px',
                  height: '60px',
                  marginRight: '16px',
                  display: 'flex',
                  borderRadius: '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(36, 153, 239, 0.2)',
                  WebkitBoxAlign: 'center',
                  WebkitBoxPack: 'center',
                }}>
                <EventAvailableIcon
                  style={{ color: 'rgb(36, 153, 239)', fontSize: '2rem' }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    color: 'rgb(148, 164, 196)',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  Completed Forms
                </Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {statsLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    stats.completed
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={classes.cardProfile}>
              <Box
                sx={{
                  width: '60px',
                  height: '60px',
                  marginRight: '16px',
                  display: 'flex',
                  borderRadius: '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(167, 152, 255, 0.2)',
                  WebkitBoxAlign: 'center',
                  WebkitBoxPack: 'center',
                }}>
                <LockResetIcon
                  style={{ color: 'rgb(167, 152, 255)', fontSize: '2rem' }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    color: 'rgb(148, 164, 196)',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  InProgress Forms
                </Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {statsLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    stats.progress
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper className={classes.cardProfile}>
              <Box
                sx={{
                  width: '60px',
                  height: '60px',
                  marginRight: '16px',
                  display: 'flex',
                  borderRadius: '50%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 107, 147, 0.2)',
                  WebkitBoxAlign: 'center',
                  WebkitBoxPack: 'center',
                }}>
                <PendingIcon
                  style={{ color: 'rgb(255, 107, 147)', fontSize: '2rem' }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    color: 'rgb(148, 164, 196)',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  Pending Forms
                </Box>
                <Box
                  sx={{
                    marginBottom: '0',
                    marginTop: '0',
                    fontSize: '18px',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                    fontFamily: 'Montserrat, sans-serif',
                  }}>
                  {statsLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    stats.pending
                  )}
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <div style={{ width: '100%' }}>
          <DataGrid
            rows={rows}
            autoHeight={true}
            columns={columns}
            loading={loading}
            density='comfortable'
            initialState={{
              pagination: {
                pageSize: 50,
              },
              columns: {
                columnVisibilityModel: {
                  mentor: false,
                  languages_spoken: false,
                  biography: false,
                  name_of_chief: false,
                  father_name: false,
                  father_hometown: false,
                  father_region: false,
                  mother_name: false,
                  mother_hometown: false,
                  mother_region: false,
                },
              },
            }}
            onSelectionModelChange={(newSelectionModel) => {
              history.push(`/gmb/auditions_list/${newSelectionModel}`);
              //   setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
      </Container>
      <Container container='true'>
        <div className='copyright__logo'>
          <a href='https://realityshows.3news.com' title='Reality shows'>
            <img
              src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
              alt='Reality Shows Portal'
              style={{
                width: '100px',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            />
          </a>
        </div>
        <div className='copyright'>
          <div className='copyright__item1'>
            <p className='copyright__text'>
              Copyright © 2023 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className='copyright__item2'>
            <p className='copyright__text'>Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default GmbAuditions;
