import React from 'react';
import Container from '@material-ui/core/Container';
import Box from '@mui/material/Box';

import './PageNotFound.css';

const PageNotFound = () => {
  React.useEffect(() => {
    // When the user scrolls the page, execute myFunction
    window.onscroll = () => {
      var header = document.getElementById('header');

      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add('sticky');
      } else {
        header.classList.remove('sticky');
      }
    };
  }, []);

  return (
    <>
      <Container
        container='true'
        id='header'
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)',
        }}>
        <div
          className='contain'
          style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div
            className='item'
            style={{ marginTop: '29px', marginBottom: '29px' }}>
            <div className='logo'>
              <a href='https://realityshows.3news.com' title='Reality shows'>
                <img
                  src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
                  alt='Reality Shows Portal'
                  style={{ width: '140px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container
        container='true'
        // spacing={3}
        maxWidth='lg'
        style={{ marginBottom: '100px', marginTop: '100px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img
            src='https://gmb2020.s3.us-east-2.amazonaws.com/voting_closed.jpeg'
            width='100%'
            alt='Voting Closed'
          />
        </Box>
      </Container>
      <Container container='true'>
        <div className='copyright__logo'>
          <a href='https://realityshows.3news.com' title='Reality shows'>
            <img
              src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
              alt='Reality Shows Portal'
              style={{
                width: '100px',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            />
          </a>
        </div>
        <div className='copyright'>
          <div className='copyright__item1'>
            <p className='copyright__text'>
              Copyright © 2023 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className='copyright__item2'>
            <p className='copyright__text'>Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PageNotFound;
