import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Button from "@mui/material/Button";
// import { Link as RouterLink } from "react-router-dom";
// import { green } from "@mui/material/colors";

function UpdateMobileApp() {
  return (
    <React.Fragment>
      <Container
        container="true"
        id="header"
        style={{
          backgroundColor: "transparent",
          backgroundImage:
            "linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)",
        }}
      >
        <div
          className="contain"
          style={{ display: "grid", gridTemplateColumns: "1fr" }}
        >
          <div
            className="item"
            style={{ marginTop: "29px", marginBottom: "29px" }}
          >
            <div className="logo">
              <a href="https://realityshows.3news.com" title="Reality shows">
                <img
                  src="https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png"
                  alt="Reality Shows Portal"
                  style={{ width: "140px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>

      <div
        style={{
          marginTop: "1%",
          textAlign: "center",
        }}
      >
        <div class="card">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <div className="illustration">
              <i class="phone">✓</i>
            </div>
            <h1>App Update</h1>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p>Update App to continue voting</p>
            </div>

            {/* {resStatus === "exists" && <p>Payment has already been processed!</p>} */}

            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.mg.realityApp"
                target="_blank"
                rel="noreferrer"
              >
                Update Now
              </a>
            </div>
          </div>
        </div>
      </div>

      <Container container="true">
        <div className="copyright__logo">
          <a href="https://realityshows.3news.com" title="Reality shows">
            <img
              src="https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png"
              alt="Reality Shows Portal"
              style={{
                width: "100px",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            />
          </a>
        </div>
        <div className="copyright">
          <div className="copyright__item1">
            <p className="copyright__text">
              Copyright © 2022 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className="copyright__item2">
            <p className="copyright__text">Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
      <Backdrop
        style={{
          color: "#fff",
        }}
        open={false}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
}

export default UpdateMobileApp;
