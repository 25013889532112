export const regions = [
  "Oti Region",
  "Bono East Region",
  "Ahafo Region",
  "Bono Region",
  "North East Region",
  "Savannah Region",
  "Western North Region",
  "Western Region",
  "Volta Region",
  "Greater Accra Region",
  "Eastern Region",
  "Ashanti Region",
  "Central Region",
  "Northern Region",
  "Upper East Region",
  "Upper West Region",
];

export const selectRegions = [
  {
    value: "Oti Region",
    label: "Oti Region",
  },
  {
    value: "Bono East Region",
    label: "Bono East Region",
  },
  {
    value: "Ahafo Region",
    label: "Ahafo Region",
  },
  {
    value: "Bono Region",
    label: "Bono Region",
  },
  {
    value: "North East Region",
    label: "North East Region",
  },
  {
    value: "Savannah Region",
    label: "Savannah Region",
  },
  {
    value: "Western North Region",
    label: "Western North Region",
  },
  {
    value: "Western Region",
    label: "Western Region",
  },
  {
    value: "Volta Region",
    label: "Volta Region",
  },
  {
    value: "Greater Accra Region",
    label: "Greater Accra Region",
  },
  {
    value: "Eastern Region",
    label: "Eastern Region",
  },
  {
    value: "Ashanti Region",
    label: "Ashanti Region",
  },
  {
    value: "Central Region",
    label: "Central Region",
  },
  {
    value: "Northern Region",
    label: "Northern Region",
  },
  {
    value: "Upper East Region",
    label: "Upper East Region",
  },
  {
    value: "Upper West Region",
    label: "Upper West Region",
  },
];
