import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";


function TransactionAlreadyProcessed() {
  return (
    <React.Fragment>
      <Container
        container='true'
        id='header'
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)',
        }}>
        <div
          className='contain'
          style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div
            className='item'
            style={{ marginTop: '29px', marginBottom: '29px' }}>
            <div className='logo'>
              <a href='https://realityshows.3news.com' title='Reality shows'>
                <img
                  src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
                  alt='Reality Shows Portal'
                  style={{ width: '140px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>

      <div style={{ marginTop: '1%' }}>
        <div class='card'>
          <div
            className='illustration'
            style={{
              background: '#b04b4b',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <span
              className='time'
              style={{
                color: '#fefefe',
                fontSize: '100px',
                lineHeight: '200px',
              }}>
              <img
                src='/exists.jpg'
                alt='Failed'
                width={'100%'}
                style={{ marginTop: '30%' }}
              />
            </span>
          </div>
          <h1 style={{ color: '#b04b4b' }}>Transaction Failed</h1>
          <p>
            Transaction Has Already Been Processed
            <br /> Thank You!
          </p>
          <p>
            <Button
              component={RouterLink}
              to='/'
              variant='contained'
              style={{
                backgroundColor: '#b04b4b',
                color: 'white',
                textDecoration: 'none',
                marginTop: '5%',
              }}>
              Go Home
            </Button>
          </p>
        </div>
      </div>

      <Container container='true'>
        <div className='copyright__logo'>
          <a href='https://realityshows.3news.com' title='Reality shows'>
            <img
              src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
              alt='Reality Shows Portal'
              style={{
                width: '100px',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            />
          </a>
        </div>
        <div className='copyright'>
          <div className='copyright__item1'>
            <p className='copyright__text'>
              Copyright © 2022 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className='copyright__item2'>
            <p className='copyright__text'>Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
      <Backdrop
        style={{
          color: '#fff',
        }}
        open={false}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}

export default TransactionAlreadyProcessed
