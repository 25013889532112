import React from "react";
import { useLocation, useHistory } from "react-router-dom";

import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Skeleton from "@material-ui/lab/Skeleton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import AssignmentIcon from "@mui/icons-material/Assignment";

import * as api from "../api/vote";

import "./Payment.css";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
  },
  media: {
    height: 0,
    paddingTop: "96.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  section1: {
    margin: theme.spacing(1, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  voteNumber: {
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
    cursor: "pointer",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  numberInput: {
    textAlign: "center !important",
  },
}));

const GmbPayment = () => {
  let history = useHistory();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramField = query.get("token");
  const classes = useStyles();
  const [formVals, setFormVals] = React.useState({
    quantity: 1,
    amount: 50.0,
  });

  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const addRemoveQuantity = (action) => {
    if (action === "add" && formVals.quantity < 5) {
      setFormVals((prevState) => {
        let newQuantity = (prevState.quantity += 1);
        let newAmount = 50.0 * newQuantity;
        return { quantity: newQuantity, amount: newAmount };
      });
    }

    if (action === "minus" && formVals.quantity > 1) {
      setFormVals((prevState) => {
        let newQuantity = (prevState.quantity -= 1);
        let newAmount = 50.0 * newQuantity;
        return { quantity: newQuantity, amount: newAmount };
      });
    }
  };

  const handlePayment = () => {
    setOpen(!open);
    const formValues = {
      quantity: formVals.quantity,
    };
    api
      .buyNow(formValues, paramField)
      .then((response) => {
        setOpen(false);
        window.open(response.checkoutDirectUrl, "_self");
      })
      .catch((err) => {
        const error = err.response.data;
        console.error(error);
      });
  };

  React.useEffect(() => {
    api
      .getUser(paramField)
      .then((response) => {
        setLoading(false);
        // setUser(response.user);
      })
      .catch((err) => {
        const error = err.response.data;
        setLoading(false);
        history.push("/page/404");
        console.error(error);
      });
  }, [paramField, history]);

  React.useEffect(() => {
    // When the user scrolls the page, execute myFunction
    window.onscroll = () => {
      var header = document.getElementById("header");

      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    };
  }, []);

  return (
    <>
      <Container
        container='true'
        id='header'
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)',
        }}>
        <div
          className='contain'
          style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div
            className='item'
            style={{ marginTop: '29px', marginBottom: '29px' }}>
            <div className='logo'>
              <a href='https://realityshows.3news.com' title='Reality shows'>
                <img
                  src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
                  alt='Reality Shows Portal'
                  style={{ width: '140px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container
        container='true'
        spacing={3}
        maxWidth='sm'
        style={{ marginBottom: '100px', marginTop: '100px' }}>
        <Card className={classes.root}>
          <CardHeader
            avatar={
              loading ? (
                <Skeleton
                  animation='wave'
                  variant='circle'
                  width={40}
                  height={40}
                />
              ) : (
                <Avatar aria-label='recipe' className={classes.avatar}>
                  <AssignmentIcon />
                </Avatar>
              )
            }
            action={
              loading ? null : (
                <Typography
                  gutterBottom
                  variant='h6'
                  className={classes.voteNumber}>
                  {`₵${formVals.amount}`}
                </Typography>
              )
            }
            title={
              loading ? (
                <Skeleton
                  animation='wave'
                  height={10}
                  width='80%'
                  style={{ marginBottom: 6 }}
                />
              ) : (
                'GMB AUDITIONS FORM 2023'
              )
            }
            subheader={
              loading ? (
                <Skeleton animation='wave' height={10} width='40%' />
              ) : (
                'Buy your application form'
              )
            }
          />
          <CardContent>
            {loading ? (
              <Skeleton
                animation='wave'
                height={15}
                style={{ marginBottom: 10 }}
              />
            ) : (
              <Typography
                variant='h6'
                color='textSecondary'
                className={classes.section1}>
                Ghana Most Beautiful 2023
              </Typography>
            )}
            <Divider variant='middle' />
            <div className={classes.section2}>
              {loading ? (
                <React.Fragment>
                  <Skeleton
                    animation='wave'
                    height={10}
                    style={{ marginBottom: 6 }}
                  />
                  <Skeleton animation='wave' height={10} width='80%' />
                </React.Fragment>
              ) : (
                <>
                  <Typography
                    variant='body2'
                    color='textSecondary'
                    gutterBottom>
                    Select the quantity
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Fab
                      size='medium'
                      aria-label='add'
                      style={{ marginRight: '20px' }}
                      onClick={() => addRemoveQuantity('minus')}>
                      <RemoveIcon />
                    </Fab>
                    <FormControl sx={{ width: '15ch' }}>
                      <OutlinedInput
                        type='number'
                        classes={'numberInput'}
                        inputProps={{
                          min: '1',
                          max: '5',
                          style: { textAlign: 'center' },
                        }}
                        value={formVals.quantity}
                      />
                    </FormControl>
                    <Fab
                      size='medium'
                      aria-label='add'
                      style={{ marginLeft: '20px' }}
                      onClick={() => addRemoveQuantity('add')}>
                      <AddIcon />
                    </Fab>
                  </div>
                  <div className={classes.section3}>
                    <Button color='primary' onClick={handlePayment}>
                      Buy Now
                    </Button>
                  </div>
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </Container>
      <Container container='true'>
        <div className='copyright__logo'>
          <a href='https://realityshows.3news.com' title='Reality shows'>
            <img
              src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
              alt='Reality Shows Portal'
              style={{
                width: '100px',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            />
          </a>
        </div>
        <div className='copyright'>
          <div className='copyright__item1'>
            <p className='copyright__text'>
              Copyright © 2023 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className='copyright__item2'>
            <p className='copyright__text'>Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default GmbPayment;
