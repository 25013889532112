import apiCall from "./apiCall";

export const payNow = async (formValues) => {
  const response = await apiCall.post("/api/v4/votes/pay_now", formValues);
  const { data } = response.data;
  return data;
};

export const postForm = async (formValues) => {
  const response = await apiCall.post(
    "/api/v1/gmb_form_details/update_form",
    formValues
  );

  const { data } = response.data;
  return data;
};

export const getContestant = async (id) => {
  const response = await apiCall.get(`/api/v3/contestants/${id}`);
  return response.data;
};

export const getStats = async () => {
  const response = await apiCall.get(`/api/v1/gmb_form_details/stats`);
  const { data } = response.data;
  return data;
};

export const getFormDetails = async () => {
  const response = await apiCall.get(`/api/v1/gmb_form_details?per_page=500`);
  return response.data;
};

export const getFormDetail = async (id) => {
  const response = await apiCall.get(`/api/v1/gmb_form_details/${id}`);
  return response.data;
};

export const getUser = async (token) => {
  const response = await apiCall.get("/api/v1/users/details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = response.data;
  return data;
};

export const getUserPromo = async (token, code) => {
  const response = await apiCall.get(
    `https://bonanza-apis.3news.com/api/app/users/promo/details?bundle_code=${code}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const buyNow = async (formValues, token) => {
  const response = await apiCall.post("/api/v1/gmb/pay_now", formValues, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { data } = response.data;
  return data;
};

export const buyBundleNow = async (formValues, token) => {
  const response = await apiCall.post(
    "https://bonanza-apis.3news.com/api/app/users/buy_bundle",
    formValues,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const updateProfile = async (formValues) => {
  const response = await apiCall.post(
    "api/v1/gmb_form_details/update_profile_pic",
    formValues,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};

export const paystackCallback = async (reference) => {
  const response = await apiCall.get(
    `/api/v3/votes/paystack_callback/?reference=${reference}`
  );
  return response.data;
};
