import React, { useCallback, useEffect, useState } from "react";

import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import Container from "@material-ui/core/Container";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiAlert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import CardContent from "@material-ui/core/CardContent";
import LoadingButton from "@mui/lab/LoadingButton";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import Divider from "@material-ui/core/Divider";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";

import { selectRegions } from "../utils/regions";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import SaveIcon from "@mui/icons-material/Save";

import * as api from "../api/vote";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 0,
    paddingTop: "96.25%", // 16:9
  },
  avatar: {
    backgroundColor: red[500],
  },
  section1: {
    margin: theme.spacing(1, 2),
  },
  section2: {
    margin: theme.spacing(2),
  },
  section3: {
    margin: theme.spacing(3, 1, 1),
  },
  voteNumber: {
    marginTop: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
    cursor: "pointer",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  numberInput: {
    textAlign: "center !important",
  },
  cardProfile: {
    backgroundColor: "#ffffff",
    color: "#1d2438",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    border: "1px solid rgb(229, 234, 242)",
    borderRadius: "8px",
    padding: "24px",
    boxShadow: "rgb(0 0 0 / 7%) 0px 0px 21px 1px",
    minHeight: "400px",
    display: "flex",
    flexDirection: "row",
    "-webkit-box-align": "center",
    alignItems: "center",
  },
  cardInput: {
    backgroundColor: "#ffffff",
    color: "#1d2438",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    border: "1px solid rgb(229, 234, 242)",
    borderRadius: "8px",
    padding: "24px",
    boxShadow: "rgb(0 0 0 / 7%) 0px 0px 21px 1px",
    minHeight: "400px",
  },
  imageUpload: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgb(29, 36, 56)",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    backgroundImage: "none",
    overflow: "hidden",
    border: "1px solid rgb(229, 234, 242)",
    borderRadius: "8px",
    padding: "24px",
    boxShadow: "rgb(0 0 0 / 7%) 0px 0px 21px 1px",
    minHeight: "400px",
    display: "flex",
    flexDirection: "column",
    WebkitBoxAlign: "center",
    alignItems: "center",
  },
  imgProfile: {
    width: "-webkit-fill-available",
  },
  backLink: {
    cursor: "pointer",
    color: "red",
    textDecoration: "underline",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GmbAuditionsPage = () => {
  const classes = useStyles();
  let { details_id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [formDetail, setFormDetail] = useState(null);
  const [readOnlyField, setReadOnlyField] = useState(true);
  const [imgUrl, setImgUrl] = useState(null);
  const [message, setMessage] = useState("");
  const [open, setOpen] = React.useState(false);

  const vertical = "top";
  const horizontal = "right";

  const { register, handleSubmit } = useForm();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const format_year = useCallback((year) => {
    if (year === undefined) {
      return null;
    }
    let new_year = year.split("/");
    let new_date = new_year[0] + "-" + new_year[2] + "-" + new_year[1];
    return new_date;
  });

  const format_year_rv = useCallback((year) => {
    if (year === undefined) {
      return;
    }

    let new_year = year.split("-");
    let new_date = new_year[0] + "/" + new_year[2] + "/" + new_year[1];
    return new_date;
  });

  React.useEffect(() => {
    api
      .getFormDetail(details_id)
      .then((response) => {
        if (response.status === false) {
          return history.push("/page/404");
        }
        setLoading(false);
        setImgUrl(response.data.profile_picture?.img_url);
        setFormDetail(response.data);
      })
      .catch((err) => {
        const error = err.response;
        setLoading(false);
        console.error(error);
      });
  }, [details_id, history]);

  useEffect(() => {}, [formDetail]);

  const findSchoolInfo = (level) => {
    return formDetail?.educational_info.find((el) => el.level === level);
  };

  const onSubmit = useCallback((data) => {
    setLoadingForm(true);
    const formValues = {
      personal_info: {
        first_name:
          data.first_name === undefined
            ? formDetail?.personal_info?.first_name
            : data.first_name,
        last_name:
          data.last_name === undefined
            ? formDetail.personal_info.last_name
            : data.last_name,
        dob:
          data.dob === undefined
            ? format_year(formDetail.personal_info.dob)
            : data.dob,
        place_of_birth:
          data.place_of_birth === undefined
            ? formDetail.personal_info.place_of_birth
            : data.place_of_birth,
        region:
          data.region === undefined
            ? formDetail.personal_info.region
            : data.region,
        height:
          data.height === undefined
            ? formDetail.personal_info.height
            : data.height,
        weight:
          data.weight === undefined
            ? formDetail.personal_info.weight
            : data.weight,
        languages_spoken:
          data.languages_spoken === undefined
            ? formDetail.languages_spoken
            : data.languages_spoken,
        biography:
          data.biography === undefined ? formDetail.biography : data.biography,
        location:
          data.location === undefined ? formDetail.location : data.location,
        name_of_chief:
          data.name_of_chief === undefined
            ? formDetail.name_of_chief
            : data.name_of_chief,
        mentor: data.mentor === undefined ? formDetail.mentor : data.mentor,
        occupation:
          data.occupation === undefined
            ? formDetail.occupation
            : data.occupation,
        user_id: formDetail?.user?.id,
      },
      phone_number_info: {
        number:
          data.phone_number === undefined
            ? formDetail.phone_number_info.number.number
            : data.phone_number,
      },
      education_info: [
        {
          name_of_school:
            data.school_name_elementary === undefined
              ? findSchoolInfo("elementary").school_name
              : data.school_name_elementary,
          level: "elementary",
          year:
            data.year_elementary === undefined
              ? findSchoolInfo("elementary").year
              : format_year_rv(data.year_elementary),
        },
        {
          name_of_school:
            data.school_name_secondary === undefined
              ? findSchoolInfo("secondary").school_name
              : data.school_name_secondary,
          level: "secondary",
          year:
            data.year_secondary === undefined
              ? findSchoolInfo("secondary").year
              : format_year_rv(data.year_secondary),
        },
        {
          name_of_school:
            data.school_name_tertiary === undefined
              ? findSchoolInfo("tertiary").school_name
              : data.school_name_tertiary,
          level: "tertiary",
          year:
            data.year_tertiary === undefined
              ? findSchoolInfo("tertiary").year
              : format_year_rv(data.year_tertiary),
        },
      ],
      family_info: {
        mother_info: {
          name:
            data.mother_name === undefined
              ? formDetail.family_info.mother_info.name
              : data.mother_name,
          hometown:
            data.mother_hometown === undefined
              ? formDetail.family_info.mother_info.hometown
              : data.mother_hometown,
          region:
            data.mother_region === undefined
              ? formDetail.family_info.mother_info.region
              : data.mother_region,
        },
        father_info: {
          name:
            data.father_name === undefined
              ? formDetail.family_info.father_info.name
              : data.father_name,
          hometown:
            data.father_hometown === undefined
              ? formDetail.family_info.father_info.hometown
              : data.father_hometown,
          region:
            data.father_region === undefined
              ? formDetail.family_info.father_info.region
              : data.father_region,
        },
      },
      gmb_form_id: formDetail?.application_form_id,
      status: "completed",
    };
    api
      .postForm(formValues)
      .then((response) => {
        if (response.status === false) {
          console.log("Error");
        }
        setLoadingForm(false);
        setFormDetail(response);
        setReadOnlyField(!readOnlyField);
        setMessage("Contestant Information has been uploaded successfully");
        setOpen(true);
      })
      .catch((err) => {
        const error = err.response;
        setLoadingForm(false);
        console.error(error);
      });
  });

  // useEffect(() => {
  //   // inputRef.current?.children[1].children[0].focus();
  //   setFirstName(formDetail?.personal_info?.first_name);
  // }, [readOnlyField]);

  const setEditFields = () => {
    setReadOnlyField(!readOnlyField);
  };

  const onChangeFile = (e) => {
    setFileLoading(true);
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append("profile_pic", file);
    formData.append("gmb_form_id", formDetail.application_form_id);
    api
      .updateProfile(formData)
      .then((response) => {
        setFileLoading(false);
        if (response.status) {
          setImgUrl(response.data.img_url);
          setOpen(true);
          setMessage(response.message);
        } else {
          setOpen(true);
          setMessage("Failed to upload File");
        }
      })
      .catch((error) => {
        setFileLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <Container
        container='true'
        id='header'
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)',
        }}>
        <div
          className='contain'
          style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div
            className='item'
            style={{ marginTop: '29px', marginBottom: '29px' }}>
            <div className='logo'>
              <a href='https://realityshows.3news.com' title='Reality shows'>
                <img
                  src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
                  alt='Reality Shows Portal'
                  style={{ width: '140px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container
        container='true'
        spacing={3}
        maxWidth='lg'
        style={{ marginBottom: '100px', marginTop: '100px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            padding: '20px',
          }}>
          <p
            href=''
            underline='none'
            className={classes.backLink}
            onClick={() => history.push(`/gmb/auditions_list/`)}>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
              className={classes.backLink}>
              Go Back
            </Typography>
          </p>
        </Box>
        <CardContent>
          {formDetail === null ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                <Skeleton
                  variant='rectangular'
                  width={200}
                  height={200}
                  animation='wave'
                />
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Skeleton animation='wave' height={50} />
                <Skeleton animation='wave' />
                <Skeleton animation='wave' height={50} />
                <Skeleton animation='wave' height={100} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={3} lg={3}>
                {imgUrl === undefined ? (
                  <Paper className={classes.imageUpload}>
                    {fileLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <Box
                          sx={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            borderRadius: '50%',
                            WebkitBoxAlign: 'center',
                            alignItems: 'center',
                            WebkitBoxPack: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgb(236, 239, 245)',
                          }}>
                          <Box
                            sx={{
                              width: '50px',
                              height: '50px',
                              display: 'flex',
                              borderRadius: '50%',
                              border: '2px solid rgb(255, 255, 255)',
                              WebkitBoxAlign: 'center',
                              alignItems: 'center',
                              WebkitBoxPack: 'center',
                              justifyContent: 'center',
                              backgroundColor: 'rgb(148, 164, 196)',
                            }}>
                            <label for='upload-Btn'>
                              <input
                                accept='image/*'
                                id='upload-Btn'
                                type='file'
                                onChange={onChangeFile}
                                style={{ display: 'none' }}
                              />
                              <IconButton
                                color='primary'
                                aria-label='upload picture'
                                component='span'>
                                <PhotoCamera />
                              </IconButton>
                            </label>
                          </Box>
                        </Box>
                        <Box
                          component='small'
                          sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            lineHeight: '1.9',
                            marginTop: '16px',
                            maxWidth: '200px',
                            display: 'block',
                            textAlign: 'center',
                            color: 'rgb(148, 164, 196)',
                            textTransform: 'none',
                            whiteSpace: 'normal',
                          }}>
                          <Typography
                            sx={{ fontSize: 14 }}
                            color='text.secondary'
                            gutterBottom>
                            Allowed *.jpeg, *.jpg, *.png, *.gif max size of 2.1
                            MB
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Paper>
                ) : (
                  <Paper className={classes.cardProfile}>
                    <Grid container spacing={1}>
                      <Grid items xs={12}>
                        <img
                          alt='dksksl'
                          src={imgUrl}
                          className={classes.imgProfile}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Paper className={classes.cardInput}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color='text.secondary'
                      gutterBottom>
                      Contestant Profile Information
                    </Typography>
                    <Divider
                      textAlign='left'
                      style={{
                        paddingBottom: '5px',
                        marginBottom: '10px',
                      }}></Divider>
                    <Grid container direction='row' spacing={1}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}>
                        <TextField
                          id='outlined-basic'
                          label='First Name'
                          variant='outlined'
                          defaultValue={formDetail?.personal_info?.first_name}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('first_name')}
                          fullWidth
                          InputProps={{
                            required: true,
                          }}
                          disabled={readOnlyField}
                        />
                      </Grid>
                      <Grid xs={12} md={6} lg={6} style={{ padding: '20px' }}>
                        <TextField
                          id='outlined-basic'
                          label='Last Name'
                          variant='outlined'
                          defaultValue={formDetail?.personal_info?.last_name}
                          {...register('last_name')}
                          InputProps={{
                            required: true,
                          }}
                          autoFocus
                          fullWidth
                          disabled={readOnlyField}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          variant='outlined'
                          label='Date of Birth'
                          defaultValue={format_year(
                            formDetail?.personal_info?.dob
                          )}
                          {...register('dob')}
                          fullWidth
                          inputProps={{
                            type: 'date',
                            required: true,
                          }}
                          disabled={readOnlyField}
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={3}
                        lg={3}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          variant='outlined'
                          label='Weight'
                          defaultValue={formDetail?.personal_info?.weight}
                          {...register('weight')}
                          inputProps={{
                            type: 'number',
                            required: true,
                          }}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={3}
                        lg={3}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          variant='outlined'
                          label='Height'
                          defaultValue={formDetail?.personal_info?.height}
                          {...register('height')}
                          inputProps={{
                            type: 'number',
                            required: true,
                          }}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Place of Birth'
                          defaultValue={
                            formDetail?.personal_info?.place_of_birth
                          }
                          {...register('place_of_birth')}
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Phone Number'
                          defaultValue={
                            formDetail?.phone_number_info?.number?.number
                          }
                          {...register('phone_number')}
                          variant='outlined'
                          inputProps={{
                            type: 'number',
                            required: true,
                          }}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-select-region'
                          label='Region'
                          select
                          {...register('region')}
                          defaultValue={formDetail?.personal_info?.region}
                          SelectProps={{
                            required: true,
                          }}
                          disabled={readOnlyField}
                          fullWidth>
                          {selectRegions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        xs={12}
                        md={12}
                        lg={12}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Biography'
                          variant='outlined'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('biography')}
                          defaultValue={formDetail?.biography}
                          InputProps={{
                            required: true,
                          }}
                          multiline
                          disabled={readOnlyField}
                          rows={5}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Location'
                          variant='outlined'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            required: true,
                          }}
                          defaultValue={formDetail?.location}
                          {...register('location')}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Languages Spoken'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant='outlined'
                          {...register('languages_spoken')}
                          InputProps={{
                            required: true,
                          }}
                          defaultValue={formDetail?.languages_spoken}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12} md={4} lg={4} style={{ padding: '20px' }}>
                        <TextField
                          id='outlined-basic'
                          label='Occupation'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('occupation')}
                          defaultValue={formDetail?.occupation}
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12} md={4} lg={4} style={{ padding: '20px' }}>
                        <TextField
                          id='outlined-basic'
                          label='Name of Mentor'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('mentor')}
                          defaultValue={formDetail?.mentor}
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Name of Chief'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('name_of_chief')}
                          defaultValue={formDetail?.name_of_chief}
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color='text.secondary'
                      gutterBottom>
                      Educational Information
                    </Typography>
                    <Divider
                      textAlign='left'
                      style={{
                        paddingBottom: '5px',
                        marginBottom: '10px',
                      }}></Divider>
                    <Grid container direction='row' spacing={1} item>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='School Name'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('school_name_elementary')}
                          defaultValue={
                            findSchoolInfo('elementary')?.school_name
                          }
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Completion Year'
                          variant='outlined'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('year_elementary')}
                          defaultValue={format_year(
                            findSchoolInfo('elementary')?.year
                          )}
                          inputProps={{ type: 'date', required: true }}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='School Name'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('school_name_secondary')}
                          defaultValue={
                            findSchoolInfo('secondary')?.school_name
                          }
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='Completion Year'
                          variant='outlined'
                          InputLabelProps={{
                            shrink: true,
                          }}
                          defaultValue={format_year(
                            findSchoolInfo('secondary')?.year
                          )}
                          {...register('year_secondary')}
                          inputProps={{ type: 'date', required: true }}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label='School Name'
                          variant='outlined'
                          defaultValue={findSchoolInfo('tertiary')?.school_name}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('school_name_tertiary')}
                          InputProps={{
                            required: true,
                          }}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid xs={12} md={6} lg={6} style={{ padding: '20px' }}>
                        <TextField
                          id='outlined-basic'
                          label='Completion Year'
                          variant='outlined'
                          defaultValue={format_year(
                            findSchoolInfo('tertiary')?.year
                          )}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ type: 'date', required: true }}
                          {...register('year_tertiary')}
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color='text.secondary'
                      gutterBottom>
                      Family Information
                    </Typography>
                    <Divider
                      textAlign='left'
                      style={{ paddingBottom: '5px', marginBottom: '10px' }}
                      // children={"Center"}
                    ></Divider>
                    <Grid container direction='row' spacing={1} item>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label="Father's Name"
                          defaultValue={
                            formDetail?.family_info?.father_info?.name
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            required: true,
                          }}
                          {...register('father_name')}
                          variant='outlined'
                          required
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label="Father's Region"
                          select
                          variant='outlined'
                          {...register('father_region')}
                          defaultValue={
                            formDetail?.family_info?.father_info?.region
                          }
                          SelectProps={{
                            required: true,
                          }}
                          disabled={readOnlyField}
                          fullWidth>
                          {selectRegions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label="Father's HomeTown"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('father_hometown')}
                          defaultValue={
                            formDetail?.family_info?.father_info?.hometown
                          }
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label="Mother's Name"
                          defaultValue={
                            formDetail?.family_info?.mother_info?.name
                          }
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            required: true,
                          }}
                          {...register('mother_name')}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label="Mothers's Region"
                          variant='outlined'
                          select
                          {...register('mother_region')}
                          defaultValue={
                            formDetail?.family_info?.mother_info?.region
                          }
                          SelectProps={{
                            required: true,
                          }}
                          disabled={readOnlyField}
                          fullWidth>
                          {selectRegions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid
                        xs={12}
                        md={4}
                        lg={4}
                        style={{ padding: '20px' }}
                        item>
                        <TextField
                          id='outlined-basic'
                          label="Mother's HomeTown"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          {...register('mother_hometown')}
                          defaultValue={
                            formDetail?.family_info?.mother_info?.hometown
                          }
                          InputProps={{
                            required: true,
                          }}
                          variant='outlined'
                          disabled={readOnlyField}
                          fullWidth
                        />
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '10px' }}
                        item>
                        <Button
                          variant='outlined'
                          size='large'
                          fullWidth
                          color={readOnlyField ? 'primary' : 'error'}
                          onClick={setEditFields}>
                          {readOnlyField ? 'Edit Form' : 'Cancel'}
                        </Button>
                      </Grid>
                      <Grid
                        xs={12}
                        md={6}
                        lg={6}
                        style={{ padding: '10px' }}
                        item>
                        <LoadingButton
                          variant='outlined'
                          type='submit'
                          size='large'
                          loading={loadingForm}
                          loadingPosition='end'
                          endIcon={<SaveIcon />}
                          fullWidth
                          color='success'
                          disabled={readOnlyField}>
                          Save
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Paper>
                </form>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Container>
      <Container container='true'>
        <div className='copyright__logo'>
          <a href='https://realityshows.3news.com' title='Reality shows'>
            <img
              src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
              alt='Reality Shows Portal'
              style={{
                width: '100px',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            />
          </a>
        </div>
        <div className='copyright'>
          <div className='copyright__item1'>
            <p className='copyright__text'>
              Copyright © 2022 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className='copyright__item2'>
            <p className='copyright__text'>Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        key={vertical + horizontal}>
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GmbAuditionsPage;
