import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Payment from "./Payment";
import GmbPayment from "./GmbPayment";
import PageNotFound from "./PageNotFound";
import GmbAuditions from "./GmbAuditions";
import GmbAuditionsPage from "./GmbAuditionsPage";
import VotingClosed from "./VotingClosed";
import BonanzaPromo from "./BonanzaPromo";
import PaystackCallback from "./PaystackCallback";
import SuccessPayment from "./SuccessPayment";
import FailedPayment from "./FailedPayment";
import TransactionAlreadyProcessed from "./TransactionAlreadyProcessed";
import UpdateMobileApp from "./UpdateMobileApp";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/tv3_bonanza" exact>
          <BonanzaPromo />
        </Route>
        <Route path="/gmb_auditions" exact>
          <GmbPayment />
        </Route>
        <Route path="/gmb/auditions_list" exact>
          <GmbAuditions />
        </Route>
        <Route path="/shows/voting_closed" exact>
          <VotingClosed />
        </Route>
        <Route path="/transaction_exists" exact>
          <TransactionAlreadyProcessed />
        </Route>
        <Route path="/gmb/auditions_list/:details_id" exact>
          <GmbAuditionsPage />
        </Route>
        <Route path="/payment">
          <PaystackCallback />
        </Route>
        <Route path="/success">
          <SuccessPayment />
        </Route>
        <Route path="/update_mobile_app" exact>
          <UpdateMobileApp />
        </Route>
        <Route path="/failed">
          <FailedPayment />
        </Route>
        <Route path="/page/404" exact>
          <PageNotFound />
        </Route>
        <Route path="/:contestant_id" exact>
          <Payment />
        </Route>
        <Redirect to="/page/404" />
      </Switch>
    </Router>
  );
};

export default App;
