import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Container from "@material-ui/core/Container";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import { green } from "@mui/material/colors";

function SuccessPayment() {
  return (
    <React.Fragment>
      <Container
        container='true'
        id='header'
        style={{
          backgroundColor: 'transparent',
          backgroundImage:
            'linear-gradient(0deg,rgba(255,255,255,0.53),#ffffff)',
        }}>
        <div
          className='contain'
          style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div
            className='item'
            style={{ marginTop: '29px', marginBottom: '29px' }}>
            <div className='logo'>
              <a href='https://realityshows.3news.com' title='Reality shows'>
                <img
                  src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
                  alt='Reality Shows Portal'
                  style={{ width: '140px' }}
                />
              </a>
            </div>
          </div>
        </div>
      </Container>

      <div style={{ marginTop: '1%', textAlign: 'center' }}>
        <div class='card'>
          <div className='illustration'>
            <i class='checkmark'>✓</i>
          </div>
          <h1>Success</h1>

          <p>
            Payment has been completed
            <br /> Successfully!
          </p>

          {/* {resStatus === "exists" && <p>Payment has already been processed!</p>} */}

          <p>
            <Button
              component={RouterLink}
              to='/'
              variant='contained'
              style={{
                backgroundColor: green[500],
                color: 'white',
                textDecoration: 'none',
                marginTop: '5%',
              }}>
              Go Home
            </Button>
          </p>
        </div>
      </div>

      <Container container='true'>
        <div className='copyright__logo'>
          <a href='https://realityshows.3news.com' title='Reality shows'>
            <img
              src='https://gmb2020.s3.us-east-2.amazonaws.com/reality-shows-portal-logo.png'
              alt='Reality Shows Portal'
              style={{
                width: '100px',
                marginTop: '30px',
                marginBottom: '20px',
              }}
            />
          </a>
        </div>
        <div className='copyright'>
          <div className='copyright__item1'>
            <p className='copyright__text'>
              Copyright © 2022 Reality Shows Portal. All Rights Reserved
            </p>
          </div>
          <div className='copyright__item2'>
            <p className='copyright__text'>Powered by: MG Digital</p>
          </div>
        </div>
      </Container>
      <Backdrop
        style={{
          color: '#fff',
        }}
        open={false}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </React.Fragment>
  );
}

export default SuccessPayment;
